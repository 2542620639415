<template>
  <div class="pageNotFound">
    <h1>404 Página não encontrada!</h1>
    <p>Voltando para a página inicial em {{ counter }}s</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PageNotFound',
    data() {
        return {
            counter: 5,
        };
    },
    created() {
        this.changeMenuVisibility(false);
        this.changeLoaderVisibility(false);
        this.regressiveCounter();
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeLoaderVisibility']),
        regressiveCounter() {
            const interval = setInterval(() => {
                if (this.counter <= 0) {
                    clearInterval(interval);
                    this.$router.push({ name: 'home' });
                } else {
                    this.counter -= 1;
                }
            }, 1000);
        },

    },
};
</script>
